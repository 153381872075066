// hack [class] on to bump the specificity
html[class], body[class] {
 font-size: 17px;
}

img {
 display: block;
}

.right-image {
 margin: 2rem 0 3rem 60px;
 float: right;
}

.left-image {
 margin: 2rem 60px 3rem 0;
 float: left;
}

.frontpage-widget img {
 height: 250px;
 object-fit: cover;
}

article img {
 margin: 1rem 0;
}
